import React, { useState } from "react";

import { Flex, Text, Icon, IconButton, Collapse } from "@chakra-ui/react";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import ReactPlayer from "react-player";

import Day28 from "./Day28";
import Day29 from "./Day29";

function StepsProgramacao() {
  const [position, setPosition] = useState(0);

  return (
    <Flex flexDir="column" flex="1" align="center">
      <Flex
        align="center"
        justify="center"
        h={["300px", "400px", "500px"]}
        mb={["20px", "40px"]}
      >
        <Flex w={["90%", "80%", "60%", "50%"]} mt="40px">
          <ReactPlayer
            playing={false}
            loop={false}
            controls
            width="100%"
            height="100%"
            style={{ objectFit: "fill" }}
            url="https://thevalve.s3.sa-east-1.amazonaws.com/cronograma.mp4"
          />
        </Flex>
      </Flex>

      <Flex align="center" my="40px">
        <IconButton
          icon={<Icon as={AiOutlineLeft} color="white" />}
          mr="20px"
          variant="outline"
          isDisabled={position === 0}
          _hover={{ bg: "black" }}
          onClick={() => setPosition(0)}
          size={["xs", "md"]}
        />
        <Text
          textAlign="center"
          color="white"
          fontSize={["18px", "43px"]}
          fontWeight="600"
          textTransform="uppercase"
        >
          Programação
        </Text>

        <Text
          ml={["10px", "20px"]}
          textAlign="center"
          color="red.300"
          fontSize={["18px", "43px"]}
          fontWeight="600"
          textTransform="uppercase"
        >
          {position === 0 ? "28|SEXTA" : "29|SÁBADO"}
        </Text>
        <IconButton
          icon={<Icon as={AiOutlineRight} color="white" />}
          ml="20px"
          variant="outline"
          _hover={{ bg: "black" }}
          isDisabled={position === 1}
          onClick={() => setPosition(1)}
          size={["xs", "md"]}
        />
      </Flex>
      <Flex w="100%" flexDir="column">
        <Collapse in={position === 0} animateOpacity>
          <Day28 />
        </Collapse>
        <Collapse in={position === 1} animateOpacity w="100%">
          <Day29 />
        </Collapse>
      </Flex>
    </Flex>
  );
}

export default StepsProgramacao;
