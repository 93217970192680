import React, { forwardRef } from "react";

import {
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";

function CustomSelect(
  {
    label,
    containerStyle,
    titleInputStyle,
    list = [],
    listPropriedade = "nome",
    value,
    error,
    ...props
  },
  ref
) {
  return (
    <FormControl flexDir="column" {...containerStyle} isInvalid={!!error}>
      {label && (
        <FormLabel fontSize="14px" mb="5px" {...titleInputStyle}>
          {label}
        </FormLabel>
      )}
      <Select {...props} ref={ref}>
        {list.map((item) => (
          <option value={item?.id} key={item?.id} selected={item?.id === value}>
            {item[listPropriedade]}
          </option>
        ))}
      </Select>
      {!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
}

export default forwardRef(CustomSelect);
