import React from "react";

import { Flex, Text, Image } from "@chakra-ui/react";

function ItemGridDescription({ image, title, description }) {
  return (
    <Flex flexDir="column" align="center" flex="1">
      <Image
        src={image}
        alt={title}
        w={["100px", "120px"]}
        h={["100px", "120px"]}
      />
      <Text
        mt="15px"
        textTransform="uppercase"
        color="#424242"
        fontWeight="400"
        fontSize={["17px", "19px"]}
        textAlign="center"
      >
        {title}
      </Text>
      <Text
        maxW="80%"
        color="#424242"
        fontWeight="400"
        fontSize={["17px"]}
        textAlign="center"
      >
        {description}
      </Text>
    </Flex>
  );
}

export default ItemGridDescription;
