import React from "react";

import { Flex, Text, Image } from "@chakra-ui/react";

function ItemGridProgramacao({ image, title, description }) {
  return (
    <Flex
      flexDir="column"
      align="center"
      flex="1"
      bg="white"
      m="20px"
      py="15px"
      px="15px"
      borderRadius="20px"
      minH="300px"
      justify="space-between"
    >
      <Text
        textTransform="uppercase"
        color="#424242"
        fontWeight="400"
        fontSize={["17px", "19px"]}
        textAlign="center"
      >
        {title}
      </Text>
      <Image
        src={image}
        alt={title}
        w={["120px", "140px"]}
        h={["120px", "140px"]}
      />

      <Text
        mt="10px"
        maxW="80%"
        color="#424242"
        fontWeight="400"
        fontSize={["17px"]}
        textAlign="center"
      >
        {description}
      </Text>
    </Flex>
  );
}

export default ItemGridProgramacao;
