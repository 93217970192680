import React from "react";

import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import { Flex, Text, IconButton, Icon } from "@chakra-ui/react";

function IngressoQtd({
  title,
  qtd = 0,
  price,
  max = Infinity,
  category,
  onChange = () => {},
}) {
  return (
    <Flex justify="space-between" align="center">
      <Flex flex="3">
        <Text fontSize="12px" color="blackAlpha.700">
          {title}
        </Text>
      </Flex>
      <Flex flex="1">
        <Text fontSize="12px" color="blackAlpha.700" textAlign="left">
          R$ {price}
        </Text>
      </Flex>

      <Flex flex="2" justify="center" display={["none", "flex"]}>
        <Text fontSize="12px" color="blackAlpha.700" textAlign="center">
          {category}
        </Text>
      </Flex>
      <Flex align="center" flex="1" justify="flex-end">
        <IconButton
          icon={<Icon as={AiOutlineMinus} />}
          size="xs"
          borderRadius="50%"
          disabled={qtd === 0}
          onClick={() => onChange(qtd - 1)}
        />
        <Text mx="10px" fontSize="12px">
          {qtd}
        </Text>
        <IconButton
          icon={<Icon as={AiOutlinePlus} />}
          size="xs"
          borderRadius="50%"
          disabled={qtd + 1 >= max}
          onClick={() => onChange(qtd + 1)}
        />
      </Flex>
    </Flex>
  );
}

export default IngressoQtd;
