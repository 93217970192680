import React, { useState } from "react";

import {
  Flex,
  Image,
  Text,
  Button,
  SimpleGrid,
  GridItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Stack,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";

import bgTop from "../../../assets/imgs/bg_top.png";
import logo1Top from "../../../assets/imgs/logo1_top.png";
import logo2Top from "../../../assets/imgs/logo2_top.png";

import imgDescriptionConteudo from "../../../assets/imgs/description_conteudo.png";
import imgDescriptionExperiencia from "../../../assets/imgs/description_experiencia.png";
import imgDescriptionTempo from "../../../assets/imgs/description_tempo.png";
import imgDescriptionTipo from "../../../assets/imgs/description_tipo.png";

import imgProgramacaoHeader from "../../../assets/imgs/programacao_header.png";
import imgProgramacaoBg from "../../../assets/imgs/programacao_bg.png";
import imgProgramacaoFooter from "../../../assets/imgs/bg_programacao_footer.png";

import imgProgramacaoGrid1 from "../../../assets/imgs/grid_programacao1.png";
import imgProgramacaoGrid2 from "../../../assets/imgs/grid_programacao2.png";
import imgProgramacaoGrid3 from "../../../assets/imgs/grid_programacao3.png";

import imgLogoFooter from "../../../assets/imgs/logo_footer.png";
import imgBgFooter from "../../../assets/imgs/bg_footer.png";
import imgAcessar from "../../../assets/imgs/duvidas_acessar.svg";

import iconSp from "../../../assets/imgs/IconesSP.png";

import {
  ItemGridDescription,
  StepsProgramacao,
  ItemGridProgramacao,
} from "./components";

import { setToken, setUser } from "../../../store/modules/usuario/actions";

import api from "../../../services/api";

function Home() {
  const history = useHistory();
  const toast = useToast();
  const dispatch = useDispatch();
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

  const { user } = useSelector((store) => store.usuario);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenRecoveryPassword,
    onOpen: onOpenRecoveryPassword,
    onClose: onCloseRecoveryPassword,
  } = useDisclosure();

  const [isOpenModalAcessar, setOpenModalAcessar] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingRecoveryPassword, setLoadingRecoveryPassword] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const list = [
    {
      title: "Experiência",
      description: "Uso da Tecnologia em um evento médico nunca visto!",
      img: imgDescriptionExperiencia,
    },
    {
      title: "2 dias de evento",
      description:
        "Hubs externas para participar dos módulos de Valvopatia Aórtica, Próteses Valvares, Valvopatia Mitral e Valvopatia Tricúspide.",
      img: imgDescriptionTempo,
    },
    {
      title: "virtual e presencial",
      description:
        "Salas virtuais e presenciais lado a lado e você com toda a autonomia de escolher o que quiser e como quiser!",
      img: imgDescriptionTipo,
    },
    {
      title: "conteúdo",
      description:
        "O melhor Line-up para te oferecer o melhor conteúdo em Cardiopatia Estrutural.",
      img: imgDescriptionConteudo,
    },
  ];

  const listProgramacaoGrid = [
    {
      img: imgProgramacaoGrid1,
      title: "",
      description: `O evento presencial lhe dará toda a EXPERIÊNCIA INOVADORA 
      em total segurança! O The Valve Club vai inovar mais uma vez e te 
      surpreender para sempre. Network, Hands-On com tecnologia de 
      ponta e ainda ganha o livro mais vendido em Valvopatias do País.`,
    },
    {
      img: imgProgramacaoGrid2,
      title: "Ganhe o livro no Evento Presencial",
      description: "Valve Basics: Valvopatia do Básico ao Avançado",
    },
    {
      img: imgProgramacaoGrid3,
      title: "",
      description:
        "Transmissão de 100% do evento em aplicativo dedicado, com toda experiência e Know How sendo passados de forma didática e tecnológica para todos, no Brasil e no mundo todo!",
    },
  ];

  const handleNavigationCheckout = () => {
    history.push("evento/pagamento/22");
  };

  const handleRecoveryPassword = () => {
    setLoadingRecoveryPassword(true);
    api
      .post("usuario/recupersenha", { email })
      .then((resp) => {
        toast({
          description: resp?.data?.message || "Solicitação enviada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        onCloseRecoveryPassword();
      })
      .catch((error) => {
        if (error.response) {
          toast({
            description:
              error?.response?.data?.error ||
              "Não foi possível concluir sua solicitação. Tente novamente mais tarde",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            description:
              "Não foi possível concluir sua solicitação. Tente novamente mais tarde",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .finally(() => {
        setLoadingRecoveryPassword(false);
      });
  };

  const handleLogin = () => {
    setLoadingLogin(true);
    const data = {
      email: email,
      senha: password,
    };
    api
      .post("usuario/auth", data)
      .then((resp) => {
        const { token, usuario } = resp.data;
        api.defaults.headers.Authorization = `Bearer ${token}`;
        dispatch(setToken(token));
        dispatch(setUser(usuario));
        history.push("evento/22");
      })
      .catch((error) => {
        if (error.response) {
          toast({
            description:
              error?.response?.data?.error ||
              "Não foi possível concluir sua solicitação. Tente novamente mais tarde",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            description:
              "Não foi possível concluir sua solicitação. Tente novamente mais tarde",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .finally(() => {
        setLoadingLogin(false);
      });
  };

  return (
    <Flex flex="1" flexDir="column" overflowY="auto">
      <Flex
        minH={["90%", "100%"]}
        h="100%"
        w="100%"
        pos="relative"
        as="section"
      >
        <Flex
          flex="1"
          zIndex="2"
          p={["20px", "50px"]}
          flexDir="column"
          justify="space-between"
        >
          <Flex justify="space-between" align="center">
            <Image
              src={logo1Top}
              w={["140px", "240px"]}
              h={["140px", "240px"]}
            />

            <Flex flexDir="column" textAlign="end">
              <Text color="white" fontSize={["17px", "19px"]}>
                LOCAL - WTC
              </Text>
              <Text color="white" fontSize={["17px", "19px"]}>
                28 DE OUTUBRO 2022
              </Text>
              <Text color="white" fontSize={["17px", "19px"]}>
                às 16 horas
              </Text>

              <Button
                my="10px"
                alignSelf="flex-end"
                maxW="200px"
                variant="outline"
                color="white"
                _hover={{ bg: "rgba(0,0,0,0.10)" }}
                onClick={() => {
                  if (user) {
                    console.log("user", user);
                    history.push("evento/22");
                  } else {
                    onOpen();
                  }
                }}
                size={["sm", "md"]}
              >
                Acessar
              </Button>
              <Button
                variant="link"
                color="red"
                size={["sm", "lg"]}
                onClick={() => setOpenModalAcessar(true)}
                textAlign="right"
              >
                {isLargerThan1280 ? (
                  "Dúvidas de como acessar? Clique aqui"
                ) : (
                  <>
                    Dúvidas de como acessar?
                    <br />
                    Clique aqui
                  </>
                )}
              </Button>
            </Flex>
          </Flex>

          <Flex
            pos="relative"
            h={["50%", "70%"]}
            w={["100%", "90%", "90%", "60%"]}
            maxW="800px"
            alignSelf="center"
            flexDir="column"
          >
            <Flex w="100%" h="100%" pos="absolute" flexDir="column">
              <ReactPlayer
                playing={true}
                loop={false}
                muted
                controls
                width="100%"
                height="100%"
                objectFit="contain"
                url="https://thevalve.s3.sa-east-1.amazonaws.com/topo.mp4"
              />
              <Flex align="center" justify="center" mt="20px" flexDir="column">
                <Button
                  colorScheme="red"
                  size={["md", "lg"]}
                  textTransform="uppercase"
                  minW={["auto", "300px"]}
                  minH={["auto", "50px"]}
                  onClick={handleNavigationCheckout}
                  bg="red"
                >
                  adquira seu ingresso
                </Button>

                <Button
                  mt="20px"
                  colorScheme="red"
                  size={["md", "lg"]}
                  textTransform="uppercase"
                  minW={["auto", "300px"]}
                  minH={["auto", "50px"]}
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLScVPuCx-U31uvj4cUx2sy_fYauzHeGds4EkNqBjp4Ol0-fhvw/viewform",
                      "_blank"
                    )
                  }
                  bg="red"
                >
                  SUBMISSÃO DE TRABALHO
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignSelf="flex-end">
            <Image src={logo2Top} w={["60px", "100px"]} h={["60px", "100px"]} />
          </Flex>
        </Flex>
        <Image
          src={iconSp}
          bottom="0"
          pos="absolute"
          zIndex="2"
          objectFit="cover"
          w={["60%", "40%"]}
          maxW="500px"
        />
        <Image src={bgTop} w="100%" h="100%" objectFit="fill" pos="absolute" />
      </Flex>
      <Flex
        w="100%"
        flexDir="column"
        p="20px"
        py={["40px", "80px"]}
        bg="#f6f6f6"
      >
        <Text
          fontFamily="Open Sans"
          fontWeight="600"
          textAlign="center"
          color="red.300"
          fontSize={["17px", "23px", "23px", "28px"]}
        >
          O SPValve que nasceu em 2020
          <br />
          Agora no formato Híbrido
        </Text>

        <Text
          mt="30px"
          fontFamily="Open Sans"
          fontWeight="700"
          textAlign="center"
          color="red.300"
          fontSize={["19px", "28px", "28px", "33px"]}
        >
          INOVADORA, DIGITAL E MODERNA!
        </Text>

        <SimpleGrid
          columns={2}
          spacing={10}
          my="60px"
          gridTemplateColumns={["100%", "48% 48%"]}
        >
          {list.map((item, index) => (
            <ItemGridDescription
              key={index}
              title={item?.title}
              description={item?.description}
              image={item?.img}
            />
          ))}
        </SimpleGrid>
      </Flex>
      <Flex pos="relative">
        <Flex
          zIndex="1"
          flexDir="column"
          align="center"
          justify="center"
          flex="1"
          h={["100px", "180px"]}
        >
          <Text
            textTransform="uppercase"
            color="white"
            fontSize={["17px", "27px"]}
          >
            28 DE OUTUBRO DE 2022
          </Text>
          <Button
            mt="10px"
            textTransform="uppercase"
            color="white"
            variant="outline"
            _hover={{ bg: "red.700" }}
            size={["md", "lg"]}
            onClick={handleNavigationCheckout}
            minW={["auto", "350px"]}
            minH={["auto", "60px"]}
          >
            adquira seu ingresso
          </Button>
        </Flex>
        <Image
          pos="absolute"
          src={imgProgramacaoHeader}
          w="100%"
          h={["100px", "180px"]}
        />
      </Flex>
      <Flex w="100%" flexDir="column" pos="relative" flex="1">
        <Image pos="absolute" src={imgProgramacaoBg} w="100%" h="110%" />
        <Flex flex="1" justify="center" zIndex="1" py="20px">
          <StepsProgramacao />
        </Flex>
      </Flex>
      <Flex w="100%" flexDir="column" pos="relative">
        <SimpleGrid columns={2} columnGap={3} zIndex="1" py="20px">
          {listProgramacaoGrid.map((item, index) => (
            <GridItem colSpan={["2", index == 2 ? "2" : "1"]} w="100%">
              <ItemGridProgramacao
                title={item?.title}
                description={item?.description}
                image={item?.img}
              />
            </GridItem>
          ))}
        </SimpleGrid>
        <Image pos="absolute" src={imgProgramacaoFooter} w="100%" h="100%" />
      </Flex>

      <Flex
        w="100%"
        flexDir="column"
        pos="relative"
        pb={[window.navigator.platform === "iPhone" ? "80px" : "0px", "0px"]}
      >
        <Flex
          flex="1"
          zIndex="1"
          align="center"
          justify="center"
          flexDir="column"
          py="50px"
          px="5%"
        >
          <Button
            textTransform="uppercase"
            colorScheme="red"
            size={["md", "lg"]}
            mb="10px"
            onClick={handleNavigationCheckout}
            minW={["auto", "300px"]}
            minH={["auto", "65px"]}
          >
            adquira seu ingresso
          </Button>
          <Image src={imgLogoFooter} w="200px" h="150px" objectFit="contain" />

          <Text
            color="white"
            fontWeight="400"
            textAlign="center"
            mt="10px"
            fontSize={["15px", "19px"]}
          >
            Av. das Nações Unidas, 12551 - Cidade Monções - São Paulo - SP World
            Trade Center
          </Text>
        </Flex>
        <Image pos="absolute" src={imgBgFooter} w="100%" h="100%" />
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Acessar Conta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="15px">
              <Input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
              />
              <Flex flexDir="column">
                <Input
                  placeholder="Senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e?.target?.value)}
                />
                <Button
                  onClick={onOpenRecoveryPassword}
                  colorScheme="black"
                  variant="unstyled"
                  alignSelf="flex-end"
                  size="xs"
                >
                  Recuperar senha
                </Button>
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              isDisabled={email.trim() === "" || password.trim() === ""}
              onClick={handleLogin}
              isLoading={loadingLogin}
            >
              Entrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenRecoveryPassword} onClose={onCloseRecoveryPassword}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Recuperar Senha</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="15px">
              <Input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseRecoveryPassword}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              isDisabled={email.trim() === ""}
              onClick={handleRecoveryPassword}
              isLoading={loadingRecoveryPassword}
            >
              Enviar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenModalAcessar}
        onClose={() => setOpenModalAcessar(false)}
        size={["4xl", "6xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton color="white" />
          <ModalBody p="0px">
            <Image src={imgAcessar} w="100%" h="100%" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Home;
