import React, { useEffect } from "react";

import { Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import Routes from "./routes";
import api from "./services/api";

function App() {
  const { user, token } = useSelector((store) => store.usuario);

  useEffect(() => {
    if (user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }, [user, token]);

  return (
    <Flex h="100vh" maxH="100vh" flex="1">
      <Routes />
    </Flex>
  );
}

export default App;
