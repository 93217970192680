import React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import EventoPagamento from "../pages/Evento/Pagamento";
import EventoHome from "../pages/Evento/Home";
import EventoDetalhes from "../pages/Evento/Detalhes";

import CursoPagamento from "../pages/Curso/Pagamento";
import CursoDashboard from "../pages/Curso/Dashboard";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <EventoHome />
        </Route>

        <Route exact path="/evento/pagamento/:id">
          <EventoPagamento />
        </Route>
        <Route exact path="/evento/:id">
          <EventoDetalhes />
        </Route>
        {/* <Route exact path="/curso/pagamento/:id">
          <CursoPagamento />
        </Route>
        <Route exact path="/cursos">
          <CursoDashboard />
        </Route> */}

        <Route exact path="/:id">
          <EventoPagamento />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
