import React, { useEffect, useState } from "react";

import {
  Flex,
  Image,
  Text,
  Icon,
  Divider,
  Spinner,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { AiOutlineQrcode, AiOutlineVideoCamera } from "react-icons/ai";

import bgTop from "../../assets/imgs/bg_top.png";

import api from "../../services/api";
import { setUser, setToken } from "../../store/modules/usuario/actions";

function Detalhes() {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, token } = useSelector((store) => store.usuario);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log("list", list);
  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    if (!user) {
      history.goBack();
    }
    loadTickets();
  }, []);

  const loadTickets = () => {
    setLoading(true);
    api
      .get(`evento/compras/${id}/usuario`)
      .then((resp) => {
        const list = resp.data || [];
        setList(list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isPresentialTicket = list.find(
    (item) => !item?.ingresso?.ingresso_online
  );

  const isOnlineTicket = list.find((item) => item?.ingresso?.ingresso_online);

  return (
    <Flex flex="1" bg="black" pos="relative" flexDir="column">
      <Image src={bgTop} w="100%" h="100%" objectFit="fill" pos="absolute" />

      <Flex
        flexDir="column"
        align="flex-end"
        w="100%"
        zIndex="1"
        mt={["2%", "2%"]}
        pr="20px"
      >
        <Text color="white" fontSize={["17px", "19px"]}>
          {user?.nome}
        </Text>
        <Button
          variant="outline"
          color="white"
          _hover={{ bg: "rgba(0,0,0,0.10)" }}
          onClick={() => {
            dispatch(setUser());
            dispatch(setToken());
            history.goBack();
          }}
          size={"sm"}
        >
          Sair
        </Button>
      </Flex>

      <Flex flex="1" mt={["5%"]} justify="center" zIndex="1">
        <Flex
          h={["80%", "50%"]}
          w={["90%", "90%", "90%", "70%"]}
          maxW="1000px"
          flexDir="column"
        >
          <Tabs color="white" colorScheme="white">
            <TabList borderBottomColor="rgba(255,255,255,0.2)">
              {(isPresentialTicket || isOnlineTicket) && (
                <>
                  <Tab>Dia 28</Tab>
                  <Tab>Dia 29</Tab>
                </>
              )}
              {isPresentialTicket && <Tab>Presencial</Tab>}
            </TabList>

            <TabPanels h="100%">
              {(isPresentialTicket || isOnlineTicket) && (
                <TabPanel h="100%" minH="40vh">
                  <Tab28 loading={loading} />
                </TabPanel>
              )}

              {(isPresentialTicket || isOnlineTicket) && (
                <TabPanel h="100%" minH="40vh">
                  <Tab29 loading={loading} />
                </TabPanel>
              )}
              {isPresentialTicket && (
                <TabPanel h="100%" minH="40vh">
                  <Presencial
                    loading={loading}
                    list={list.filter(
                      (item) => !item?.ingresso?.ingresso_online
                    )}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </Flex>
  );
}

const Tab28 = ({ loading }) => {
  const [itemSelected, setItemSelected] = useState();

  const list = [
    {
      nome: "Sala Magna/Propedêutica",
      link: "https://youtu.be/4qvIlJFZjFY7sk8-b8sy-vq0g-q9mr-d086",
    },
    {
      nome: "Sala Diagnóstico",
      link: "https://youtu.be/bZ8wG3wW77Iwugv-t6yd-sp65-qgq0-asg6",
    },
    {
      nome: "Sala Intervenção",
      link: "https://youtu.be/nmz246rB6hosak9-yq14-mwyw-v9zw-am7b",
    },
  ];

  return (
    <Flex w="100%" h="100%" flex="1" flexDir={["column", "row"]}>
      <Flex flex="1" bg={"#19191C"} align="center" justify="center">
        {itemSelected?.link && itemSelected?.link.length > 0 ? (
          <ReactPlayer
            autoPlay
            playing={true}
            controls
            width="100%"
            height="100%"
            style={{
              objectFit: "fill",
              backgroundColor: "black",
              minHeight: "300px",
            }}
            url={itemSelected?.link}
          />
        ) : (
          <Flex flex="1" align="center" justify="center">
            <Text
              color="white"
              fontWeight="600"
              textAlign="center"
              fontSize={["16px", "20px"]}
            >
              {itemSelected
                ? "O evento ainda não está disponível."
                : "Selecione uma sala para começar"}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex
        bg="#19191C"
        maxW={["100%", "300px"]}
        w="100%"
        h="100%"
        overflowY="auto"
        flexDir="column"
      >
        <Text
          color="white"
          px="10px"
          textTransform="uppercase"
          textAlign="center"
          fontWeight="600"
          my="10px"
        >
          Salas
        </Text>
        <Divider mb="5px" borderColor="rgba(255,255,255,0.3)" />
        {!loading &&
          list.map((item) => (
            <ItemListTicket
              key={item?.link}
              name={item?.nome}
              isOnline
              onClick={() => setItemSelected(item)}
              isSelected={item === itemSelected}
            />
          ))}
        {!loading && list.length === 0 && (
          <Flex flex="1" align="center" justify="center" p="20px">
            <Text color="white" textTransform="uppercase" textAlign="center">
              Nenhum ingresso encontrado
            </Text>
          </Flex>
        )}
        {loading && (
          <Flex flex="1" align="center" justify="center" p="20px">
            <Spinner size="xl" color="white" />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const Tab29 = ({ loading }) => {
  const [itemSelected, setItemSelected] = useState();

  const list = [
    {
      nome: "Sala Magna/Propedêutica",
      link: "https://youtu.be/9NvwbtBy49ov903-tdqu-195u-3rtx-a2f0",
    },
    {
      nome: "Sala Diagnóstico",
      link: "https://youtu.be/nSQpwFQRt6E3qt8-bw72-k46f-f37y-8dvu",
    },
    {
      nome: "Sala Intervenção",
      link: "https://youtu.be/e7c06-PbQFkwm1y-7jfe-472s-xm51-5c9j",
    },
  ];

  return (
    <Flex w="100%" h="100%" flex="1" flexDir={["column", "row"]}>
      <Flex flex="1" bg={"#19191C"} align="center" justify="center">
        {itemSelected?.link && itemSelected?.link.length > 0 ? (
          <ReactPlayer
            autoPlay
            playing={true}
            controls
            width="100%"
            height="100%"
            style={{
              objectFit: "fill",
              backgroundColor: "black",
              minHeight: "300px",
            }}
            url={itemSelected?.link}
          />
        ) : (
          <Flex flex="1" align="center" justify="center">
            <Text
              color="white"
              fontWeight="600"
              textAlign="center"
              fontSize={["16px", "20px"]}
            >
              {itemSelected
                ? "O evento ainda não está disponível."
                : "Selecione uma sala para começar"}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex
        bg="#19191C"
        maxW={["100%", "300px"]}
        w="100%"
        h="100%"
        overflowY="auto"
        flexDir="column"
      >
        <Text
          color="white"
          px="10px"
          textTransform="uppercase"
          textAlign="center"
          fontWeight="600"
          my="10px"
        >
          Salas
        </Text>
        <Divider mb="5px" borderColor="rgba(255,255,255,0.3)" />
        {!loading &&
          list.map((item) => (
            <ItemListTicket
              key={item?.link}
              name={item?.nome}
              isOnline
              onClick={() => setItemSelected(item)}
              isSelected={item === itemSelected}
            />
          ))}
        {!loading && list.length === 0 && (
          <Flex flex="1" align="center" justify="center" p="20px">
            <Text color="white" textTransform="uppercase" textAlign="center">
              Nenhum ingresso encontrado
            </Text>
          </Flex>
        )}
        {loading && (
          <Flex flex="1" align="center" justify="center" p="20px">
            <Spinner size="xl" color="white" />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const Presencial = ({ list = [], loading }) => {
  const [itemSelected, setItemSelected] = useState();

  useEffect(() => {
    if (list.length > 0) {
      setItemSelected(list[0]);
    }
  }, []);

  return (
    <Flex w="100%" h="100%" flex="1" flexDir={["column", "row"]}>
      <Flex
        flex="1"
        bg={!itemSelected ? "#19191C" : "white"}
        align="center"
        justify="center"
        py={"20px"}
      >
        {itemSelected && <QRCode value={itemSelected?.qr_code || ""} />}
      </Flex>
      <Flex
        bg="#19191C"
        maxW={["100%", "300px"]}
        w="100%"
        h="100%"
        overflowY="auto"
        flexDir="column"
      >
        <Text
          color="white"
          px="10px"
          textTransform="uppercase"
          textAlign="center"
          fontWeight="600"
          my="10px"
        >
          Ingressos
        </Text>
        <Divider mb="5px" borderColor="rgba(255,255,255,0.3)" />
        {!loading &&
          list.map((item) => (
            <ItemListTicket
              key={item?.id}
              name={item?.ingresso?.nome}
              isOnline={item?.ingresso?.ingresso_online}
              onClick={() => setItemSelected(item)}
              isSelected={item === itemSelected}
            />
          ))}
        {!loading && list.length === 0 && (
          <Flex flex="1" align="center" justify="center" p="20px">
            <Text color="white" textTransform="uppercase" textAlign="center">
              Nenhum ingresso encontrado
            </Text>
          </Flex>
        )}
        {loading && (
          <Flex flex="1" align="center" justify="center" p="20px">
            <Spinner size="xl" color="white" />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const ItemListTicket = ({ name, isOnline, isSelected, onClick = () => {} }) => (
  <Flex
    py="10px"
    px="15px"
    justify="space-between"
    alignItems="center"
    cursor="pointer"
    _hover={{
      bg: "rgba(90,90,90,0.15)",
    }}
    borderColor="whiteAlpha.100"
    borderBottomWidth="1px"
    onClick={onClick}
  >
    <Text
      color="white"
      fontSize="14px"
      mr="10px"
      fontWeight={isSelected ? "800" : "400"}
    >
      {name}
    </Text>
    <Icon
      color="white"
      as={isOnline ? AiOutlineVideoCamera : AiOutlineQrcode}
    />
  </Flex>
);

export default Detalhes;
