import {createStore} from 'redux';
import {persistStore} from 'redux-persist';

import rootReducer from './modules/rootReducer';
import persistedReducer from './persistRedurcers';

const store = createStore(persistedReducer(rootReducer));
const persistor = persistStore(store);

export {store, persistor};
