import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  Flex,
  Stack,
  Image,
  Text,
  Button,
  Divider,
  Collapse,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  StackDivider,
} from "@chakra-ui/react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validateCPF } from "validations-br";
import { useParams } from "react-router-dom";
import { BsFillCheckCircleFill, BsExclamationCircle } from "react-icons/bs";
import axios from "axios";

import { Input, Select, IngressoQtd } from "../../components";

import api from "../../services/api";
import { uploadFiles } from "../../utils";

import logo1Top from "../../assets/imgs/logo1_top.png";

function Pagamento() {
  const [event, setEvent] = useState();
  const [cardBrand, setCardBrand] = useState();
  const [loading, setLoading] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [ingressos, setIngressos] = useState([]);
  const [qtdParcelaList, setQtdParcelaList] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState();
  const [userExists, setUserExists] = useState(true);

  let { id } = useParams();

  const formSchema = yup.object().shape({
    nome: yup.string().min(5, "Nome inválido").required("Nome é obrigatório"),
    email: yup.string().email("Email inválido").required("Email é obrigatório"),
    confirmacao_email: yup
      .string()
      .email("Email inválido")
      .required("Email é obrigatório"),
    cpf: yup
      .string()
      .required("CPF é obrigatório")
      .test("test-invalid-cpf", "CPF inválido", (cpf) => validateCPF(cpf)),
    cpf_cartao: yup
      .string()
      .required("CPF é obrigatório")
      .test("test-invalid-cpf", "CPF inválido", (cpf) => validateCPF(cpf)),
    celular: yup
      .string()
      .required("Celular é obrigatório")
      .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/gi, "Celular inválido"),
    numero_cartao: yup
      .string()
      .min(16, "Cartão inválido")
      .required("Número do cartão é obrigatório"),
    nome_cartao: yup
      .string()
      .min(5, "Nome inválido")
      .required("Nome é obrigatório"),
    mes: yup.string().min(1, "mês inválido").required("mês é obrigatório"),
    ano: yup.string().min(2, "ano inválido").required("ano é obrigatório"),
    cvv: yup.string().min(3, "cvv inválido").required("cvv é obrigatório"),
    qtd_parcela: yup
      .string()
      .min(1, "quantidade inválida")
      .required("quantidade é obrigatório"),
    data_nascimento: yup
      .date("data de nascimento inválida")
      .required("data de nascimento é obrigatória"),
    comprovante_meia_entrada: ingressos.find((item) => item?.estudante)
      ? yup.mixed().required("Campo é obrigatório")
      : yup.mixed().notRequired(),
    senha: userExists
      ? yup.string().notRequired()
      : yup.string().min(6, "senha inválida").required("senha é obrigatória"),
    cep: yup.string().min(9, "CEP inválido").required("Campo é obrigatório"),
    endereco_numero: yup.string().required("Campo é obrigatório"),
    cidade: yup.string().required("Campo é obrigatório"),
    estado: yup.string().required("Campo é obrigatório"),
    logradouro: yup.string().required("Campo é obrigatório"),
    bairro: yup.string().required("Campo é obrigatório"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (!id) {
      alert("Evento inválido");
      return;
    }
    setPaymentStatus();
    loadEvent();
    loadPagSeguro();
  }, []);

  useEffect(() => {
    try {
      const number = (watch("numero_cartao") || "").match(/\d/g)?.join("");
      if (number && number.length >= 6) {
        window.PagSeguroDirectPayment.getBrand({
          cardBin: parseInt(number.substring(0, 6)),
          success: (response) => {
            setCardBrand(response?.brand);
          },
          error: () => {
            setCardBrand();
          },
        });
      } else {
        setCardBrand();
      }
    } catch (error) {}
  }, [watch("numero_cartao")]);

  useEffect(() => {
    getQtdParcelaList();
  }, [cardBrand, ingressos]);

  useEffect(() => {
    if (watch("cep") && watch("cep").length === 9) {
      axios
        .get(`https://viacep.com.br/ws/${watch("cep")}/json`)
        .then((resp) => {
          const data = resp.data;
          setValue("estado", data?.uf || "", { shouldValidate: true });
          setValue("cidade", data?.localidade || "", { shouldValidate: true });
          setValue("bairro", data?.bairro || "", { shouldValidate: true });
          if (data?.logradouro !== "") {
            setValue("logradouro", data?.logradouro || "", {
              shouldValidate: true,
            });
          }
          if (data?.complemento !== "") {
            setValue("complemento", data?.complemento || "", {
              shouldValidate: true,
            });
          }
        });
    }
  }, [watch("cep")]);

  const onSubmit = (data) => {
    setLoading(true);
    const requestDataCard = new Promise((resolve, reject) => {
      window.PagSeguroDirectPayment.createCardToken({
        cardNumber: (data?.numero_cartao || "").match(/\d/g).join(""),
        brand: cardBrand?.name,
        cvv: data?.cvv,
        expirationMonth: data?.mes,
        expirationYear: data?.ano,
        success: (response) => {
          window.PagSeguroDirectPayment.onSenderHashReady(async (resp) => {
            try {
              if (resp.status == "error") {
                reject("Error ao processar dados do cartão");
                return false;
              }
              const hash = resp?.senderHash;
              const token = response?.card?.token;

              const dataEvent = {
                hash,
                token,
                cpf: data?.cpf,
                cpf_cartao: data?.cpf,
                name: data?.nome_cartao,
                evento_id: id,
                ingressos: ingressos
                  .filter((item) => item.qtd > 0)
                  .map((item) => ({ id: item?.id, quantidade: item?.qtd })),
                valor_total: getQtdParcela().total,
                valor_parcela: getQtdParcela()?.valor,
                qtd_parcelas: parseInt(data?.qtd_parcela),
                telefone: data?.celular,
                email: data?.email,
                nome: data?.nome,
                senha: data?.senha,
                birthDate: format(
                  new Date(data?.data_nascimento),
                  "dd/MM/yyyy"
                ),
                address: {
                  street: data?.logradouro,
                  number: data?.endereco_numero,
                  complement: data?.complemento,
                  district: data?.bairro,
                  city: data?.cidade,
                  state: data?.estado,
                  postalCode: data?.cep.replace("-", ""),
                },
              };
              if (data?.comprovante_meia_entrada) {
                const comprovante_meia_entrada = data?.comprovante_meia_entrada;
                comprovante_meia_entrada.path = (
                  window.URL || window.webkitURL
                ).createObjectURL(data?.comprovante_meia_entrada);

                const { data: files } = await uploadFiles("evento/arquivos", [
                  comprovante_meia_entrada,
                ]);
                if (files.length === 1) {
                  dataEvent.comprovante_meia_entrada_id = files[0]?.id;
                } else {
                  reject("Não foi possível enviar os arquivos anexados");
                  return;
                }
              }
              resolve(dataEvent);
            } catch (error) {
              reject(error);
            }
          });
        },
        error: function () {
          reject("Error ao processar dados do cartão");
        },
      });
    });
    requestDataCard
      .then((resp) => {
        api
          .post("evento/inscricao", resp)
          .then((resp) => {
            const { status } = resp.data;
            setPaymentStatus(status);
            setModalPayment(true);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                if (error.response.data?.errors) {
                  let msg = "";
                  (error.response?.data?.errors || []).forEach((item) => {
                    msg = msg + item?.message + "\n";
                  });
                  alert(msg);
                } else {
                  alert(
                    error.response?.data?.error ||
                      "Não foi possível processar esse cartão. Tente novamente mais tarde"
                  );
                }
              } else {
                alert(
                  "Não foi possível processar esse cartão. Tente novamente mais tarde"
                );
              }
            } else {
              alert(
                "Não foi possível processar esse cartão. Tente novamente mais tarde"
              );
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const loadPagSeguro = async () => {
    const response = await api.post("/ps/sessao");
    const { id } = response.data;
    window.PagSeguroDirectPayment.setSessionId(id);
  };

  const loadEvent = () => {
    api.get(`evento/detalhes/${id}`).then((resp) => {
      setEvent(resp?.data);
    });
  };

  const getQtdParcelaList = () => {
    const value = ingressos.reduce(
      (previousValue, item) =>
        previousValue +
        item.qtd * event.ingressos.find((obj) => obj.id === item?.id)?.preco,
      0
    );

    if (!cardBrand) {
      setQtdParcelaList([]);
      return;
    }
    if (value > 0) {
      window.PagSeguroDirectPayment.getInstallments({
        amount: value,
        brand: cardBrand?.name,
        success: function (response) {
          const parcelas = response?.installments[cardBrand?.name] || 0;
          const count =
            event?.quantidade_parcelas > parcelas.length
              ? parcelas.length
              : event?.quantidade_parcelas;
          const list = [];

          for (let i = 1; i <= count || 0; i++) {
            try {
              list.push({
                id: i,
                nome: `${i} x de ${parcelas[i - 1]?.installmentAmount}`,
                valor: parcelas[i - 1]?.installmentAmount,
                total: parcelas[i - 1]?.totalAmount,
              });
            } catch (error) {}
          }
          setQtdParcelaList(list);
        },
      });
    } else {
      setQtdParcelaList([]);
    }
  };

  const getQtdParcela = () => {
    const list = qtdParcelaList;
    const qtdParcela = parseInt(watch("qtd_parcela") || "1");
    return list.find((item) => item.id == qtdParcela);
  };

  const getMaskCard = () => {
    if (cardBrand?.name === "diners") {
      return "999 999999 99999";
    }
    if (cardBrand?.name === "amex") {
      return "999 999999 999999";
    }
    return "9999 9999 9999 9999";
  };

  const changeQtdIngresso = (item, qtd) => {
    const index = ingressos.findIndex((obj) => obj?.id === item?.id);
    if (index > -1) {
      const list = [...ingressos];
      list[index].qtd = qtd;
      if (qtd === 0) {
        setIngressos([...ingressos.filter((obj) => obj?.id !== item?.id)]);
      } else {
        setIngressos([...list]);
      }
    } else {
      const list = [...ingressos];
      list.push({ id: item?.id, qtd, estudante: item?.estudante });
      setIngressos([...list]);
    }
  };

  const verifyUserExists = () => {
    api
      .get(`usuario/email?email=${watch("email")}`)
      .then((resp) => {
        setUserExists(resp?.data?.exists);
      })
      .catch(() => {
        setUserExists(true);
      });
  };

  return (
    <Flex
      flex="1"
      align={["flex-start"]}
      justify="center"
      bg="#F7F9FA"
      py={["2%"]}
      px={["2%", "10%"]}
      overflowY="auto"
    >
      <Flex
        flex="1"
        w="100%"
        maxW="700px"
        borderWidth="1px"
        borderRadius="5px"
        p="20px"
        bg="white"
        flexDir="column"
        pb={[window.navigator.platform === "iPhone" ? "120px" : "0px", "0px"]}
      >
        <Flex>
          <Image
            src={logo1Top}
            alt={event?.nome}
            boxSize="90px"
            borderRadius="5px"
          />
          <Flex ml="15px" flexDir="column">
            <Text fontSize="18px" fontWeight="600">
              {event?.nome}
            </Text>
            <Text fontSize="14px" color="gray.600" fontWeight="400">
              {event?.resumo}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir="column" as="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack w="100%" spacing="20px" mt="20px">
            <Controller
              name="nome"
              control={control}
              render={({ field }) => (
                <Input
                  label="Nome completo"
                  placeholder="Digite seu nome completo"
                  containerStyle={{ w: "100%" }}
                  size="sm"
                  borderRadius="5px"
                  error={errors.nome}
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  label="Seu email"
                  placeholder="Digite seu email para receber o acesso"
                  containerStyle={{ w: "100%" }}
                  size="sm"
                  borderRadius="5px"
                  {...field}
                  name="email"
                  error={errors.email}
                  onBlur={() => {
                    if (watch("email")) {
                      verifyUserExists();
                    }
                  }}
                />
              )}
            />
            <Controller
              name="confirmacao_email"
              control={control}
              render={({ field }) => (
                <Input
                  label="Confirme seu email"
                  placeholder="Digite novamente seu email"
                  containerStyle={{ w: "100%" }}
                  size="sm"
                  borderRadius="5px"
                  {...field}
                  error={errors.confirmacao_email}
                />
              )}
            />
            {!userExists && (
              <Controller
                name="senha"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Senha"
                    placeholder="Digite sua senha de acesso"
                    containerStyle={{ w: "100%" }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    error={errors.senha}
                  />
                )}
              />
            )}
            <Flex flexDir={["column", "row"]}>
              <Controller
                name="cpf"
                control={control}
                render={({ field }) => (
                  <Input
                    label="CPF"
                    placeholder="Digite o número do seu CPF"
                    containerStyle={{ w: "100%", mr: ["0px", "15px"] }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    mask="999.999.999-99"
                    error={errors.cpf}
                  />
                )}
              />

              <Controller
                name="celular"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Celular"
                    placeholder="Digite o número do seu celular"
                    containerStyle={{
                      w: "100%",
                      ml: ["0px", "15px"],
                      mt: ["10px", "0px"],
                    }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    mask="(99) 99999-9999"
                    error={errors.celular}
                  />
                )}
              />
            </Flex>

            <Collapse
              animateOpacity
              in={ingressos.find((item) => item?.estudante)}
            >
              <Input
                label="Comprovante de estudante"
                size="sm"
                borderRadius="5px"
                accept="application/pdf,image/*"
                type="file"
                name="comprovante_meia_entrada"
                error={errors.comprovante_meia_entrada}
                onChange={(e) =>
                  setValue(
                    "comprovante_meia_entrada",
                    e.target.files.length > 0 ? e.target.files[0] : null,
                    { shouldValidate: true }
                  )
                }
              />
            </Collapse>
            <Flex flexDir="column">
              <Text fontSize="14px">Ingressos</Text>
              <Stack
                mt="10px"
                spacing="10px"
                borderWidth="0.5px"
                borderColor="gray.100"
                py="10px"
                px="10px"
                borderRadius="5px"
                divider={<StackDivider borderColor="gray.200" />}
              >
                {(event?.ingressos || []).map((item) => (
                  <IngressoQtd
                    title={item?.nome}
                    onChange={(qtd) => changeQtdIngresso(item, qtd)}
                    max={item?.quantidade - parseInt(item?.qtd_vendas) || 0}
                    price={item?.preco}
                    category={item?.categoria?.nome}
                    qtd={
                      ingressos.find((obj) => obj?.id === item?.id)?.qtd || 0
                    }
                  />
                ))}
              </Stack>
            </Flex>
          </Stack>
          <Flex pt="20px" flexDir="row" justify="space-between">
            <Stack
              flexDir="column"
              flex="1"
              mr={["0px", "0px", "20px"]}
              spacing="15px"
            >
              <Controller
                name="numero_cartao"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Número do cartão"
                    placeholder="Digite somente números"
                    containerStyle={{ w: "100%" }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    mask={getMaskCard()}
                    error={errors.numero_cartao}
                  />
                )}
              />

              <Controller
                name="nome_cartao"
                control={control}
                render={({ field }) => (
                  <Input
                    textTransform="uppercase"
                    label="Nome do titular"
                    placeholder="Digite o nome impresso no cartão"
                    containerStyle={{ w: "100%" }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    error={errors.nome_cartao}
                  />
                )}
              />
              <Controller
                name="cpf_cartao"
                control={control}
                render={({ field }) => (
                  <Input
                    label="CPF do titular"
                    placeholder="Digite o número do seu CPF"
                    size="sm"
                    containerStyle={{ w: "100%" }}
                    borderRadius="5px"
                    {...field}
                    mask="999.999.999-99"
                    error={errors.cpf_cartao}
                  />
                )}
              />
              <Flex justify="space-around">
                <Controller
                  name="mes"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Mês"
                      placeholder="MM"
                      size="sm"
                      borderRadius="5px"
                      containerStyle={{ w: "100%", mr: "15px" }}
                      {...field}
                      error={errors.mes}
                      list={[...new Array(12)].map((_, index) => ({
                        id: index + 1 >= 10 ? index + 1 : "0" + (index + 1),
                        nome: index + 1 >= 10 ? index + 1 : "0" + (index + 1),
                      }))}
                    />
                  )}
                />

                <Controller
                  name="ano"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Ano"
                      placeholder="AA"
                      size="sm"
                      borderRadius="5px"
                      containerStyle={{ w: "100%", mr: "15px" }}
                      {...field}
                      error={errors.ano}
                      list={[...new Array(30)].map((_, index) => ({
                        id: new Date().getFullYear() + index,
                        nome: new Date().getFullYear() + index,
                      }))}
                    />
                  )}
                />

                <Controller
                  name="cvv"
                  control={control}
                  render={({ field }) => (
                    <Input
                      label="CVV"
                      placeholder="CVV"
                      size="sm"
                      type="number"
                      borderRadius="5px"
                      containerStyle={{ w: "100%" }}
                      maxlength={String(cardBrand?.cvvSize || 3)}
                      {...field}
                      error={errors.cvv}
                    />
                  )}
                />
              </Flex>
              <Controller
                name="data_nascimento"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Data de nascimento"
                    placeholder="dd/mm/aaaa"
                    size="sm"
                    type="date"
                    borderRadius="5px"
                    containerStyle={{ w: "100%" }}
                    error={errors.data_nascimento}
                    {...field}
                  />
                )}
              />
            </Stack>
            <Flex mt="10px" align="center" display={["none", "none", "flex"]}>
              <Cards
                cvc={watch("cvv") || ""}
                expiry={(watch("mes") || "00") + (watch("ano") || "00")}
                name={watch("nome_cartao") || ""}
                number={watch("numero_cartao") || ""}
                locale={{ valid: "válido até" }}
                placeholders={{ name: "****" }}
              />
            </Flex>
          </Flex>
          <Stack mt="10px" spacing="10px">
            <Flex flexDir={["column", "row"]}>
              <Controller
                name="cep"
                control={control}
                render={({ field }) => (
                  <Input
                    label="CEP"
                    placeholder="CEP de cobrança"
                    containerStyle={{ w: "100%", mr: ["0px", "15px"] }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    mask="99999-999"
                    error={errors.cep}
                  />
                )}
              />

              <Controller
                name="endereco_numero"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Número"
                    containerStyle={{
                      w: "100%",
                      ml: ["0px", "15px"],
                      mt: ["10px", "0px"],
                    }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    error={errors.endereco_numero}
                  />
                )}
              />
            </Flex>

            <Controller
              name="logradouro"
              control={control}
              render={({ field }) => (
                <Input
                  label="Logradouro"
                  placeholder=""
                  containerStyle={{ w: "100%" }}
                  size="sm"
                  borderRadius="5px"
                  {...field}
                  name="logradouro"
                  error={errors.bairro}
                />
              )}
            />
            <Controller
              name="bairro"
              control={control}
              render={({ field }) => (
                <Input
                  label="Bairro"
                  placeholder=""
                  containerStyle={{ w: "100%" }}
                  size="sm"
                  borderRadius="5px"
                  {...field}
                  name="bairro"
                  error={errors.bairro}
                />
              )}
            />
            <Controller
              name="complemento"
              control={control}
              render={({ field }) => (
                <Input
                  label="Complemento"
                  placeholder=""
                  containerStyle={{ w: "100%" }}
                  size="sm"
                  borderRadius="5px"
                  {...field}
                  name="complemento"
                  error={errors.complemento}
                />
              )}
            />

            <Flex flexDir={["column", "row"]}>
              <Controller
                name="cidade"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Cidade"
                    placeholder="Cidade"
                    containerStyle={{ w: "100%", mr: ["0px", "15px"] }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    error={errors.cidade}
                  />
                )}
              />

              <Controller
                name="estado"
                control={control}
                render={({ field }) => (
                  <Input
                    label="Estado"
                    placeholder="Estado"
                    containerStyle={{
                      w: "100%",
                      ml: ["0px", "15px"],
                      mt: ["10px", "0px"],
                    }}
                    size="sm"
                    borderRadius="5px"
                    {...field}
                    error={errors.estado}
                  />
                )}
              />
            </Flex>
          </Stack>
          <Collapse
            in={ingressos.filter((item) => item.qtd > 0).length > 0}
            animateOpacity
          >
            <Controller
              name="qtd_parcela"
              control={control}
              render={({ field }) => (
                <Select
                  label="Selecione o número de parcelas"
                  size="sm"
                  borderRadius="5px"
                  placeholder="selecione"
                  containerStyle={{ w: "100%", mt: "20px" }}
                  list={qtdParcelaList}
                  {...field}
                  error={errors.qtd_parcela}
                />
              )}
            />

            <Text color="blackAlpha.600" mt="20px">
              Detalhes da compra
            </Text>
            <Divider />
            <Flex mt="10px" mx="10px" justify="flex-end" align="center">
              <Text
                fontSize="14px"
                fontWeight="400"
                ml="20px"
                color="blackAlpha.800"
              >
                {getQtdParcela()?.nome || ""}
              </Text>
            </Flex>
          </Collapse>
          <Button
            mt="50px"
            size="sm"
            colorScheme="green"
            type="submit"
            isLoading={loading}
            disabled={
              !cardBrand ||
              ingressos.filter((item) => item.qtd !== 0).length === 0 ||
              ingressos.length === 0
            }
          >
            Comprar agora
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={modalPayment} onClose={() => setModalPayment(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {paymentStatus == "3"
              ? "Pagamento concluído"
              : "Pagamento em análise"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex align="center" justify="center" flexDir="column">
              <Icon
                as={
                  paymentStatus == "3"
                    ? BsFillCheckCircleFill
                    : BsExclamationCircle
                }
                color={paymentStatus == "3" ? "#6DB26F" : "#697689"}
                fontSize="100px"
              />
              <Text textAlign="center" mt="20px">
                {paymentStatus == "3"
                  ? "Seu pagamento foi processado com sucesso. Você receberá um email contendo as informações de acesso."
                  : "Seu pagamento está em análise. Você receberá um email contendo as informações de acesso assim que a transação for processada."}
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => setModalPayment(false)} variant="ghost">
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Pagamento;
