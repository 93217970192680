export function setUser(user) {
  return {
    type: '@user/SET_USER',
    payload: {user},
  };
}
export function setToken(token) {
  return {
    type: '@user/SET_TOKEN',
    payload: {token},
  };
}
