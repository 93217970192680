import axios from "axios";

const local = "http://localhost:3333/";
const server = "https://apithevalve.ndb.app/";

const api = axios.create({
  baseURL: server,
});

export default api;
