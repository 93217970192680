import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    purple: {
      900: "#543685",
      800: "#754cb8",
      300: "rgba(117,76,184,0.8)",
      200: "rgba(117,76,184,0.4)",
    },
    red: {
      600: "rgb(155, 18, 16)",
      400: "#DC0B48",
      300: "#E04654",
    },
  },
  fonts: {
    heading: "Open Sans",
    body: "Open Sans",
  },
  styles: {
    global: {
      body: {
        overflowY: "hidden",
        overflowX: "hidden",
        bg: "white.700",
        color: "black.800",
        display: "standalone",
        boxSizing: "border-box",
      },
    },
  },
  components: {
    Button: { baseStyle: { _focus: { boxShadow: "none" } } },
    Input: { baseStyle: { _focus: { boxShadow: "none" } } },
    Image: { baseStyle: { _focus: { boxShadow: "none" } } },
    Box: { baseStyle: { _focus: { boxShadow: "none" } } },
    Flex: { baseStyle: { _focus: { boxShadow: "none" } } },
    Spinner: { baseStyle: { _focus: { boxShadow: "none" } } },
    Icon: { baseStyle: { _focus: { boxShadow: "none" } } },
    IconButton: { baseStyle: { _focus: { boxShadow: "none" } } },
    Radio: { baseStyle: { _focus: { boxShadow: "none" } } },
    TextArea: { baseStyle: { _focus: { boxShadow: "none" } } },
    FormControl: { baseStyle: { _focus: { boxShadow: "none" } } },
    Text: { baseStyle: { _focus: { boxShadow: "none" } } },
    Table: { baseStyle: { _focus: { boxShadow: "none" } } },
    Popover: { baseStyle: { _focus: { boxShadow: "none", boxColor: "red" } } },
    PopoverContent: { baseStyle: { _focus: { boxShadow: "none" } } },
    PopoverTrigger: { baseStyle: { _focus: { boxShadow: "none" } } },
    Portal: { baseStyle: { _focus: { boxShadow: "none" } } },
    Checkbox: { baseStyle: { _focus: { boxShadow: "none" } } },
    CheckboxGroup: { baseStyle: { _focus: { boxShadow: "none" } } },
    Tabs: {
      baseStyle: {
        tab: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
});
