import React from "react";

import { Flex, Stack, Text, Image, SimpleGrid } from "@chakra-ui/react";

import avatarDrBrunaErbano2 from "../../../../../assets/imgs/avatar_dr_bruna_erbano2.png";
import avatarDrBrunaErbano from "../../../../../assets/imgs/avatar_dr_bruna_erbano.png";
import avatarDrRicardoCosta from "../../../../../assets/imgs/avatar_dr_ricardo_costa.png";
import avatarDrTiagoBignoto from "../../../../../assets/imgs/avatar_dr_tiago_bignoto.png";
import avatarDrTiagoBignoto2 from "../../../../../assets/imgs/avatar_dr_tiago_bignoto2.png";
import avatarDrTiagoBignoto5 from "../../../../../assets/imgs/avatar_dr_tiago_bignoto5.png";

import imgCard2 from "../../../../../assets/imgs/img_28_card2.png";
import imgCard3 from "../../../../../assets/imgs/img_28_card3.png";
import imgCard4 from "../../../../../assets/imgs/img_28_card4.png";
import imgCard5 from "../../../../../assets/imgs/img_28_card5.png";
import imgCard6 from "../../../../../assets/imgs/img_28_card6.png";
import imgCard7 from "../../../../../assets/imgs/img_28_card7.png";
import imgCard8 from "../../../../../assets/imgs/img_28_card8.png";
import imgCard9 from "../../../../../assets/imgs/img_28_card9.png";
import imgCard10 from "../../../../../assets/imgs/img_28_card10.png";
import imgCard11 from "../../../../../assets/imgs/img_28_card11.png";
import imgCard12 from "../../../../../assets/imgs/img_28_card12.png";

import avatarDrGabrielDoreto from "../../../../../assets/imgs/avatar_dr_gabriel_doreto.png";
import avatarPainel from "../../../../../assets/imgs/avatar_painel.png";
import avatarPainel2 from "../../../../../assets/imgs/avatar_painel2.png";
import avatarDrNathaliaCaetano from "../../../../../assets/imgs/avatar_dr_nathalia_caetano.png";
import avatarDrSilvioBarberato from "../../../../../assets/imgs/avatar_dr_silvio_barberato.png";
import avatarDrRenataCassar from "../../../../../assets/imgs/avatar_dr_renata_cassar.png";
import avatarDrRafaelModesto from "../../../../../assets/imgs/avatar_dr_rafael_modesto.png";
import avatarDrElinthonVeronese from "../../../../../assets/imgs/avatar_dr_elinthon_veronese.png";
import avatarDrJoseMariani from "../../../../../assets/imgs/avatar_dr_jose_mariani.png";

import avatarDrOtavioBerwanger from "../../../../../assets/imgs/avatar_dr_oravio_berwanger.png";
import avatarDrJoseRochaFaria from "../../../../../assets/imgs/avatar_dr_jose_rocha_faria.png";
import avatarDrRenatoLopes from "../../../../../assets/imgs/avatar_dr_renato_lopes.png";
import avatarJoseAlencar from "../../../../../assets/imgs/avatar_dr_jose_alencar.png";

import avatarPedroNicz from "../../../../../assets/imgs/avatar_dr_pedro_nicz.png";
import avatarRodrigoBarretto from "../../../../../assets/imgs/avatar_dr_rodrigo_barretto.png";
import avatarWiltonGomes from "../../../../../assets/imgs/avatar_dr_wilton_gomes.png";
import avatarFabioSandoli from "../../../../../assets/imgs/avatar_dr_fabio_sandoli.png";

import avatarDrManuelFelipe from "../../../../../assets/imgs/avatar_dr_manuel_felipe.png";
import avatarDrTalitaMialksi from "../../../../../assets/imgs/avatar_dr_talita_mialksi.png";

import avatarDrMarcioLima from "../../../../../assets/imgs/avatar_dr_marcio_lima.png";
import avatarDrDavidLeBihan from "../../../../../assets/imgs/avatar_dr_david_le_bihan.png";
import avatarDrFlavioTarasoutchi from "../../../../../assets/imgs/avatar_dr_flavio_tarasoutchi.png";
import avatarDrFranciscoCosta from "../../../../../assets/imgs/avatar_dr_francisco_costa.png";
import avatarDrIbramPinto from "../../../../../assets/imgs/avatar_dr_ibram_pinto.png";
import avatarDrLeonardoGuimaraes from "../../../../../assets/imgs/avatar_dr_leonardo_guimaraes.png";

const bgItens = "#f6f6f6";
const borderHour = "#920F0D";
function Day28() {
  return (
    <Stack px={["2%", "2%", "20%"]} spacing="20px">
      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          16H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "column", "row"]}
        >
          <Flex flexDir="column" flex="1">
            <Text
              color={borderHour}
              fontWeight="600"
              fontSize={["14px", "14px", "14px", "18px"]}
            >
              MAIN ARENA
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["16px", "16px", "16px", "20px"]}
              textTransform="uppercase"
              textAlign={["center", "left"]}
            >
              O mundo mudando pela cardiopatia estrutural
            </Text>
          </Flex>
          <Flex flex="1" justify="space-around">
            <Image
              src={avatarDrTiagoBignoto}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
            <Image
              src={avatarDrBrunaErbano}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
            <Image
              src={avatarDrRicardoCosta}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          16H40
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
        >
          <Flex justify="center" align="center">
            <Image
              src={avatarDrTiagoBignoto}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
          <Flex flexDir="column" flex="1" align="center">
            <Text
              fontWeight="600"
              fontSize={["14px", "14px", "14px", "18px"]}
              textTransform="uppercase"
              color="blackAlpha.900"
              textAlign="center"
            >
              Caso Clínico 1
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "18px", "24px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              Insuficiência Mitral Funcional
            </Text>
          </Flex>
          <Flex justify="space-around">
            <Image
              src={imgCard2}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
        </Flex>
      </Flex>

      <LargeCard
        title="Propedêutica 1"
        image={imgCard3}
        list={[
          {
            hora: "17H00",
            avatar: avatarDrGabrielDoreto,
            text: "História Natural da Insuficiência Mitral Funcional",
          },
          {
            hora: "17H10",
            avatar: avatarDrTiagoBignoto5,
            text: "Exame Físico da Insuficiência Mitral Funcional",
          },
          {
            hora: "17H20",
            avatar: avatarDrNathaliaCaetano,
            text: "“Eco Point-Of-Care” na Insuficiência Mitral Funcional",
          },
          {
            hora: "17H30",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />
      <LargeCard
        title="Diagnóstico 1"
        image={imgCard4}
        list={[
          {
            hora: "17H00",
            avatar: avatarDrSilvioBarberato,
            text: "Parâmetros Ecocardiográficos da Insuficiência Mitral Funcional ",
          },
          {
            hora: "17H10",
            avatar: avatarDrRenataCassar,
            text: "Métodos avançados na Ecocardiografia da Insuficiência Mitral Funcional ",
          },
          {
            hora: "17H20",
            avatar: avatarDrRafaelModesto,
            text: "Proporcionalidade na Insuficiência Mitral Funcional ",
          },
          {
            hora: "17H30",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />
      <LargeCard
        title="Intervenção 1"
        image={imgCard5}
        list={[
          {
            hora: "17H00",
            avatar: avatarDrBrunaErbano2,
            text: "Indicações de Intervenção na Insuficiência Mitral Funcional",
          },
          {
            hora: "17H20",
            avatar: avatarDrJoseMariani,
            text: "Tratamento percutâneo da Insuficiência Mitral Funcional reparo bordo-a-bordo é o ideal?",
          },
          {
            hora: "17H30",
            avatar: avatarDrElinthonVeronese,
            text: "Abordagem Cirúrgica da Insuficiência Mitral Funcional ",
          },
          {
            hora: "17H30",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          17H50
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={"column"}
          pos="relative"
        >
          <Flex flexDir="column" flex="1">
            <Flex
              justify="space-between"
              pos="absolute"
              right="0"
              left="0"
              top="0"
            >
              <Text
                color={borderHour}
                fontWeight="600"
                fontSize={["14px", "14px", "18px", "18px"]}
                m={["10px", "20px"]}
              >
                MAIN ARENA
              </Text>
              <Image
                src={imgCard6}
                h={["0px", "0px", "70px", "100px"]}
                objectFit="contain"
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" mt="20px">
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "28px", "32px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              Deep Dives 1
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "14px", "20px", "24px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              A “Medicina Baseada em Evidências” na Cardiopatia Estrutural
            </Text>
          </Flex>

          <SimpleGrid
            columns={[2, 4]}
            justify="space-around"
            mt="30px"
            px={["0px", "10%"]}
            spacing="20px"
          >
            <Image
              src={avatarDrOtavioBerwanger}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarDrJoseRochaFaria}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarDrRenatoLopes}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarJoseAlencar}
              boxSize="150px"
              objectFit="contain"
            />
          </SimpleGrid>
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          18H20
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
          pos="relative"
          minH={["80px", "100px", "120px"]}
        >
          <Flex flexDir="column" flex="1" p="20px" justify="center">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["20px", "20px", "20px", "26px"]}
              textTransform="uppercase"
              textAlign={["left", "center"]}
            >
              intervalo + coffee
            </Text>
          </Flex>
          <Image
            src={imgCard7}
            h={["80px", "100px", "120px"]}
            pos="absolute"
            right="0"
          />
        </Flex>
      </Flex>

      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          19H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={"column"}
          pos="relative"
        >
          <Flex flexDir="column" flex="1">
            <Flex
              justify="space-between"
              pos="absolute"
              right="0"
              left="0"
              top="0"
            >
              <Text
                color="black"
                fontWeight="600"
                fontSize={["14px", "14px", "18px", "18px"]}
                m={["10px", "20px"]}
              >
                HUB 1
              </Text>
              <Image
                src={imgCard8}
                h={["0px", "0px", "0px", "0px", "200px"]}
                objectFit="contain"
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" mt="20px" zIndex="1">
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "28px", "32px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              Discussão - Live Case MitraClip
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "14px", "20px", "24px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              Abordagem Intervencionista da Insuficiência Mitral
            </Text>
          </Flex>

          <SimpleGrid
            columns={[2, 4]}
            justify="space-around"
            mt="30px"
            px={["0px", "10%"]}
            spacing="20px"
          >
            <Image src={avatarPedroNicz} boxSize="150px" objectFit="contain" />
            <Image
              src={avatarRodrigoBarretto}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarWiltonGomes}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarFabioSandoli}
              boxSize="150px"
              objectFit="contain"
            />
          </SimpleGrid>
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          19H50
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["row"]}
          minH={["80px", "100px", "120px"]}
          px="20px"
        >
          <Image
            src={imgCard9}
            h={["80px", "100px", "120px"]}
            display={["none", "none", "none", "flex"]}
            visibility={["none", "none", "none", "hidden"]}
          />
          <Flex flexDir="column" flex="1" p="20px" justify="center">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["20px", "20px", "20px", "26px"]}
              textTransform="uppercase"
              textAlign={["left", "center"]}
            >
              Programa “The Valve Club Academy”
            </Text>
          </Flex>
          <Image src={imgCard9} h={["80px", "100px", "120px"]} />
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          20H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
        >
          <Flex justify="center" align="center">
            <Image
              src={avatarDrTiagoBignoto2}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
          <Flex
            flexDir="column"
            flex="1"
            align="center"
            justify="center"
            mt={["15px", "0px"]}
          >
            <Text
              fontWeight="600"
              fontSize={["14px", "14px", "14px", "18px"]}
              textTransform="uppercase"
              color="blackAlpha.900"
              textAlign="center"
            >
              Caso Clínico 2
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "18px", "24px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              Insuficiência Aórtica
            </Text>
          </Flex>
          <Flex justify="space-around">
            <Image
              src={imgCard10}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
        </Flex>
      </Flex>

      <LargeCard
        title="Propedêutica 2"
        image={imgCard4}
        list={[
          {
            hora: "20H20",
            avatar: avatarDrManuelFelipe,
            text: "História Natural da Insuficiência Aórtica",
          },
          {
            hora: "20H30",
            avatar: avatarDrTiagoBignoto5,
            text: "Exame Físico na Insuficiência Aórtica",
          },
          {
            hora: "20H40",
            avatar: avatarDrTalitaMialksi,
            text: "“Eco Point-Of-Care” na Insuficiência Aórtica",
          },
          {
            hora: "20H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <LargeCard
        title="Diagnóstico 2"
        image={imgCard4}
        list={[
          {
            hora: "20H20",
            avatar: avatarDrMarcioLima,
            text: "Parâmetros Ecocardiográficos na Insuficiência Aórtica ",
          },
          {
            hora: "20H30",
            avatar: avatarDrDavidLeBihan,
            text: "Métodos Avançados na Ecocardiografia da Insuficiência Aórtica ",
          },
          {
            hora: "20H40",
            avatar: avatarDrIbramPinto,
            text: "Multimodalidade no Diagnóstico da Insuficiência Aórtica",
          },
          {
            hora: "20H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <LargeCard
        title="Intervenção 2"
        image={imgCard11}
        list={[
          {
            hora: "20H20",
            avatar: avatarDrFlavioTarasoutchi,
            text: "Indicações de Intervenção na Insuficiência Aórtica",
          },
          {
            hora: "20H30",
            avatar: avatarDrLeonardoGuimaraes,
            text: "Tratamento Percutâneo da Insuficiência Aórtica",
          },
          {
            hora: "20H40",
            avatar: avatarDrFranciscoCosta,
            text: "Abordagem Cirúrgica da Insuficiência Aórtica. Existe como preservar a valva nativa? ",
          },
          {
            hora: "20H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          21H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
          pos="relative"
          minH={["100px", "120px", "140px"]}
        >
          <Flex flexDir="column" flex="1" p="20px" justify="center">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["20px", "20px", "20px", "26px"]}
              textTransform="uppercase"
              textAlign={["left", "center"]}
            >
              VALVE CONNECTION
            </Text>
          </Flex>
          <Flex pos="absolute" right="15" align="center" top="0" bottom="0">
            <Image src={imgCard12} h={["80px", "100px", "120px"]} />
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  );
}

const LargeCard = ({ list, image, title }) => (
  <Flex flexDir="column" pos="relative">
    {list.map((item, index) => (
      <Flex justify="space-between" flex="1" key={title + index}>
        <Flex flexDir="column" justify="center">
          {index === 0 && (
            <Text
              display="gone"
              my="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "16px", "18px", "26px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              .
            </Text>
          )}
          <Text
            borderLeftColor={borderHour}
            borderLeftWidth="2px"
            paddingLeft="5px"
            color="white"
            fontWeight="600"
            fontSize={["12px", "20px"]}
            alignSelf="center"
          >
            {item?.hora}
          </Text>
        </Flex>
        <Flex
          bg={bgItens}
          flex="1"
          py={"10px"}
          px={["10px", "20px"]}
          ml={["5px", "30px"]}
          borderTopLeftRadius={index === 0 && ["20px", "40px"]}
          borderTopRightRadius={index === 0 && ["20px", "40px"]}
          borderBottomLeftRadius={index + 1 === list.length && ["20px", "40px"]}
          borderBottomRightRadius={
            index + 1 === list.length && ["20px", "40px"]
          }
          flexDir="column"
        >
          {index === 0 && (
            <Text
              my="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "16px", "18px", "26px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              {title}
            </Text>
          )}
          <Flex align="center">
            <Image
              src={item?.avatar}
              boxSize={["70px", "100px", "120px"]}
              objectFit="contain"
            />
            <Text
              ml={["10px", "20px"]}
              mt="10px"
              color="blackAlpha.800"
              fontWeight="500"
              fontSize={["12px", "14px", "16px", "22px"]}
              textTransform="uppercase"
              mr={["0px", "0px", "10%"]}
            >
              {item?.text}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    ))}
    <Flex
      pos="absolute"
      right="0"
      align="center"
      top="0"
      bottom="0"
      display={["none", "none", "flex"]}
    >
      <Image src={image} h="300px" objectFit="contain" />
    </Flex>
  </Flex>
);

export default Day28;
