import React, { useState, useEffect } from "react";

import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import api from "../../services/api";

import { setToken, setUser } from "../../store/modules/usuario/actions";

function ModalLogin({ isOpen, onClose = () => {}, onSuccess = () => {} }) {
  const toast = useToast();
  const dispatch = useDispatch();

  const {
    isOpen: isOpenRecoveryPassword,
    onOpen: onOpenRecoveryPassword,
    onClose: onCloseRecoveryPassword,
  } = useDisclosure();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadingRecoveryPassword, setLoadingRecoveryPassword] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  useEffect(() => {
    setEmail("");
    setPassword("");
    setLoadingLogin(false);
    setLoadingRecoveryPassword(false);
  }, [isOpen]);

  const handleRecoveryPassword = () => {
    setLoadingRecoveryPassword(true);
    api
      .post("usuario/recupersenha", { email })
      .then((resp) => {
        toast({
          description: resp?.data?.message || "Solicitação enviada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
        onCloseRecoveryPassword();
      })
      .catch((error) => {
        if (error.response) {
          toast({
            description:
              error?.response?.data?.error ||
              "Não foi possível concluir sua solicitação. Tente novamente mais tarde",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            description:
              "Não foi possível concluir sua solicitação. Tente novamente mais tarde",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .finally(() => {
        setLoadingRecoveryPassword(false);
      });
  };

  const handleLogin = () => {
    setLoadingLogin(true);
    const data = {
      email: email,
      senha: password,
    };
    api
      .post("usuario/auth", data)
      .then((resp) => {
        const { token, usuario } = resp.data;
        api.defaults.headers.Authorization = `Bearer ${token}`;
        dispatch(setToken(token));
        dispatch(setUser(usuario));
        onSuccess();
      })
      .catch((error) => {
        if (error.response) {
          toast({
            description:
              error?.response?.data?.error ||
              "Não foi possível concluir sua solicitação. Tente novamente mais tarde",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            description:
              "Não foi possível concluir sua solicitação. Tente novamente mais tarde",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }
      })
      .finally(() => {
        setLoadingLogin(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Acessar Conta</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="15px">
              <Input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
              />
              <Flex flexDir="column">
                <Input
                  placeholder="Senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e?.target?.value)}
                />
                <Button
                  onClick={onOpenRecoveryPassword}
                  colorScheme="black"
                  variant="unstyled"
                  alignSelf="flex-end"
                  size="xs"
                >
                  Recuperar senha
                </Button>
              </Flex>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              isDisabled={email.trim() === "" || password.trim() === ""}
              onClick={handleLogin}
              isLoading={loadingLogin}
            >
              Entrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenRecoveryPassword} onClose={onCloseRecoveryPassword}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Recuperar Senha</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="15px">
              <Input
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCloseRecoveryPassword}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              isDisabled={email.trim() === ""}
              onClick={handleRecoveryPassword}
              isLoading={loadingRecoveryPassword}
            >
              Enviar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalLogin;
