import React, { forwardRef } from "react";
import {
  Input,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";

function CustomInput({ label, name, containerStyle, error, ...props }, ref) {
  return (
    <FormControl flexDir="column" {...containerStyle} isInvalid={!!error}>
      {label && (
        <FormLabel fontSize="14px" mb="5px">
          {label}
        </FormLabel>
      )}
      <Input ref={ref} maskChar={null} as={InputMask} {...props} />

      {!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
}

export default forwardRef(CustomInput);
