import React from "react";

import { Flex, Stack, Text, Image, SimpleGrid } from "@chakra-ui/react";

import avatarDrBrunaErbano3 from "../../../../../assets/imgs/avatar_dr_bruna_erbano3.png";
import avatarDrBrunaErbano2 from "../../../../../assets/imgs/avatar_dr_bruna_erbano2.png";
import avatarDrBrunaErbano from "../../../../../assets/imgs/avatar_dr_bruna_erbano.png";
import avatarDrTiagoBignoto from "../../../../../assets/imgs/avatar_dr_tiago_bignoto.png";
import avatarDrTiagoBignoto2 from "../../../../../assets/imgs/avatar_dr_tiago_bignoto2.png";
import avatarDrTiagoBignoto3 from "../../../../../assets/imgs/avatar_dr_tiago_bignoto3.png";
import avatarDrTiagoBignoto4 from "../../../../../assets/imgs/avatar_dr_tiago_bignoto4.png";
import avatarDrTiagoBignoto5 from "../../../../../assets/imgs/avatar_dr_tiago_bignoto5.png";

import imgCard2 from "../../../../../assets/imgs/img_29_card2.png";
import imgCard3 from "../../../../../assets/imgs/img_28_card3.png";
import imgCard4 from "../../../../../assets/imgs/img_28_card4.png";
import imgCard5 from "../../../../../assets/imgs/img_28_card5.png";
import imgCard6 from "../../../../../assets/imgs/img_28_card6.png";
import imgCard7 from "../../../../../assets/imgs/img_28_card7.png";
import imgCard9 from "../../../../../assets/imgs/img_28_card9.png";
import imgCard11 from "../../../../../assets/imgs/img_28_card11.png";
import imgCard12 from "../../../../../assets/imgs/img_28_card12.png";

import imgCard29_3 from "../../../../../assets/imgs/img_29_card3.png";
import imgCard29_4 from "../../../../../assets/imgs/img_29_card4.png";
import imgCard29_5 from "../../../../../assets/imgs/img_29_card5.png";
import imgCard29_6 from "../../../../../assets/imgs/img_29_card6.png";
import imgCard29_7 from "../../../../../assets/imgs/img_29_card7.png";

import avatarPainel from "../../../../../assets/imgs/avatar_painel.png";
import avatarPainel2 from "../../../../../assets/imgs/avatar_painel2.png";
import avatarDrRenatoLopes2 from "../../../../../assets/imgs/avatar_dr_renato_lopes2.png";
import avatarPedroNicz from "../../../../../assets/imgs/avatar_dr_pedro_nicz.png";
import avatarPedroNicz2 from "../../../../../assets/imgs/avatar_dr_pedro_nicz2.png";
import avatarRodrigoBarretto from "../../../../../assets/imgs/avatar_dr_rodrigo_barretto.png";
import avatarRodrigoBarretto2 from "../../../../../assets/imgs/avatar_dr_rodrigo_barretto2.png";
import avatarDrManuelFelipe from "../../../../../assets/imgs/avatar_dr_manuel_felipe.png";
import avatarDrTalitaMialksi2 from "../../../../../assets/imgs/avatar_dr_talita_mialksi2.png";
import avatarDrThiagoRosa from "../../../../../assets/imgs/avatar_dr_thiago_rosa.png";

import avatarDrPhilippePibarot from "../../../../../assets/imgs/avatar_dr_philippe_pibarot.png";
import avatarDrMariaEstefania from "../../../../../assets/imgs/avatar_dr_maria_estefania.png";
import avatarDrGustavoBlume from "../../../../../assets/imgs/avatar_dr_gustavo_blume.png";
import avatarDrViniciusEsteves from "../../../../../assets/imgs/avatar_dr_vinicius_esteves.png";
import avatarDrViniciusEsteves2 from "../../../../../assets/imgs/avatar_dr_vinicius_esteves2.png";
import avatarDrAttilioGalhardo from "../../../../../assets/imgs/avatar_dr_attilio_galhardo.png";
import avatarDrAttilioGalhardo2 from "../../../../../assets/imgs/avatar_dr_attilio_galhardo2.png";
import avatarDrAlexandreAbizaid from "../../../../../assets/imgs/avatar_dr_alexandre_abizaid.png";

import avatarDrMinaRomano from "../../../../../assets/imgs/avatar_dr_mina_romano.png";
import avatarDrArianePacheco from "../../../../../assets/imgs/avatar_dr_ariane_pacheco.png";
import avatarDrIdelzuita from "../../../../../assets/imgs/avatar_dr_idelzuita_liporace.png";

import avatarDrRhanderson from "../../../../../assets/imgs/avatar_dr_rhanderson.png";
import avatarDrRemoHolanda from "../../../../../assets/imgs/avatar_dr_remo_holanda.png";
import avatarDrEduardoLapa from "../../../../../assets/imgs/avatar_dr_eduardo_lapa.png";
import avatarDrBarbaraValente from "../../../../../assets/imgs/avatar_dr_barbara_valente.png";
import avatarDrMuriloJardim from "../../../../../assets/imgs/avatar_dr_murilo_jardim.png";
import avatarDrMarceloViera from "../../../../../assets/imgs/avatar_dr_marcelo_viera.png";
import avatarDrBernadoLopes from "../../../../../assets/imgs/avatar_dr_bernado_lopes.png";
import avatarDrFelipeCampos from "../../../../../assets/imgs/avatar_dr_felipe_campos.png";
import avatarDrFelipeCampos2 from "../../../../../assets/imgs/avatar_dr_felipe_campos2.png";
import avatarDrFranciscoCosta from "../../../../../assets/imgs/avatar_dr_francisco_costa.png";
import avatarDrFranciscoCosta2 from "../../../../../assets/imgs/avatar_dr_francisco_costa2.png";
import avatarDrTalitaMialski from "../../../../../assets/imgs/avatar_dr_talita_mialski.png";
import avatarDrJairoPinheiro from "../../../../../assets/imgs/avatar_dr_jairo_pinheiro.png";
import avatarDrMathias from "../../../../../assets/imgs/avatar_mathias.png";
import avatarDrLeonardoGuimaraes2 from "../../../../../assets/imgs/avatar_dr_leonardo_guimaraes2.png";
import avatarDrPedroLemos from "../../../../../assets/imgs/avatar_dr_pedro_lemos.png";

const bgItens = "#f6f6f6";
const borderHour = "#920F0D";
function Day29() {
  return (
    <Stack px={["2%", "2%", "20%"]} spacing="20px">
      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          08H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={"column"}
          pos="relative"
        >
          <Flex flexDir="column" flex="1">
            <Flex
              justify="space-between"
              pos="absolute"
              right="0"
              left="0"
              top="0"
            >
              <Text
                color={borderHour}
                fontWeight="600"
                fontSize={["14px", "14px", "18px", "18px"]}
                m={["10px", "20px"]}
              >
                MAIN ARENA
              </Text>
              <Image
                src={imgCard6}
                h={["0px", "0px", "70px", "100px"]}
                objectFit="contain"
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" mt="20px">
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "28px", "32px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              Deep Dives 2
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "14px", "20px", "24px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              A Ciência Aplicada no Mundo Real! Como Lidar com Casos
              Desafiadores?
            </Text>
          </Flex>

          <SimpleGrid
            columns={[2, 4]}
            justify="space-around"
            mt="30px"
            px={["0px", "10%"]}
            spacing="20px"
          >
            <Image
              src={avatarDrPhilippePibarot}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarDrTiagoBignoto3}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarDrBrunaErbano3}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarDrRenatoLopes2}
              boxSize="150px"
              objectFit="contain"
            />
          </SimpleGrid>
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          08H30
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
        >
          <Flex px={["10px", "20px"]} pb={["10px", "20px"]} justify="center">
            <Image
              src={avatarDrTiagoBignoto}
              h={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
          <Flex flexDir="column" flex="1" align="center" p={["10px", "20px"]}>
            <Text
              fontWeight="600"
              fontSize={["14px", "14px", "14px", "18px"]}
              textTransform="uppercase"
              color="blackAlpha.900"
              textAlign="center"
            >
              Caso Clínico 3
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "18px", "24px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              Estenose Aórtica Estágio D3
            </Text>
          </Flex>
          <Flex px={["10px", "20px"]} justify="center">
            <Image
              src={imgCard2}
              h={["60px", "80px", "100px"]}
              objectFit="contain"
            />
          </Flex>
        </Flex>
      </Flex>

      <LargeCard
        title="Propedêutica 3"
        image={imgCard3}
        list={[
          {
            hora: "08H50",
            avatar: avatarDrFelipeCampos2,
            text: "História Natural na Estenose Aórtica ",
          },
          {
            hora: "09H00",
            avatar: avatarDrTalitaMialski,
            text: "Exame Físico na Estenose Aórtica",
          },
          {
            hora: "09H10",
            avatar: avatarDrThiagoRosa,
            text: "“Eco Point-Of-Care” na Estenose Aórtica ",
          },
          {
            hora: "09H20",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <LargeCard
        title="Diagnóstico 3"
        image={imgCard4}
        list={[
          {
            hora: "08H50",
            avatar: avatarDrMariaEstefania,
            text: "O Papel do Cardiologista na Escolha da Multimodalidade de Imagem na Estenose Aórtica Paradoxal",
          },
          {
            hora: "09H00",
            avatar: avatarDrGustavoBlume,
            text: "Parâmetros Ecocardiográficos na Estenose Aórtica",
          },
          {
            hora: "09H10",
            avatar: avatarDrJairoPinheiro,
            text: "Métodos Avançados na Ecocardiografia da Estenose Aórtica",
          },
          {
            hora: "09H20",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <LargeCard
        title="Intervenção 3"
        image={imgCard5}
        list={[
          {
            hora: "08H50",
            avatar: avatarDrTiagoBignoto4,
            text: "Indicações de Intervenção na Estenose Aórtica",
          },
          {
            hora: "09H00",
            avatar: avatarDrViniciusEsteves,
            text: "Tratamento Percutâneo da Estenose Aórtica",
          },
          {
            hora: "09H10",
            avatar: avatarDrAttilioGalhardo,
            text: "Novidades no Tratamento Percutâneo da Estenose Aórtica",
          },
          {
            hora: "09H20",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />
      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          09H30
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
          pos="relative"
          minH={["80px", "100px", "120px"]}
        >
          <Flex flexDir="column" flex="1" p="20px" justify="center">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["20px", "20px", "20px", "26px"]}
              textTransform="uppercase"
              textAlign={["left", "center"]}
            >
              intervalo + coffee
            </Text>
          </Flex>
          <Image
            src={imgCard7}
            h={["80px", "100px", "120px"]}
            pos="absolute"
            right="0"
          />
        </Flex>
      </Flex>

      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          10H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={"column"}
          pos="relative"
        >
          <Flex flexDir="column" flex="1">
            <Flex
              justify="space-between"
              pos="absolute"
              right="0"
              left="0"
              top="0"
            >
              <Text
                color="black"
                fontWeight="600"
                fontSize={["14px", "14px", "18px", "18px"]}
                m={["10px", "20px"]}
              >
                HUB 2
              </Text>
              <Image
                src={imgCard29_3}
                h={["0px", "0px", "0px", "0px", "120px"]}
                objectFit="contain"
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" mt="20px" zIndex="1">
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "28px", "32px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              VitaFlow Case
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "14px", "20px", "24px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              Discussão Live Case TAVI
            </Text>
          </Flex>

          <Flex justify="space-around" mt="30px" flexWrap="wrap">
            <Image
              src={avatarDrAlexandreAbizaid}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarDrViniciusEsteves2}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarDrTiagoBignoto}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarDrMathias}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          10H50
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
          pos="relative"
          minH={["80px", "100px", "120px"]}
        >
          <Flex flexDir="column" flex="1" p="20px" justify="center">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["20px", "20px", "20px", "26px"]}
              textTransform="uppercase"
              textAlign={["left", "center"]}
            >
              Programa “The Valve Club Academy”
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          11H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
        >
          <Flex justify="center" align="center" p={["10px", "20px"]}>
            <Image
              src={avatarDrTiagoBignoto2}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
          <Flex
            flexDir="column"
            flex="1"
            align="center"
            justify="center"
            mt={["15px", "0px"]}
            p={["10px", "20px"]}
          >
            <Text
              fontWeight="600"
              fontSize={["14px", "14px", "14px", "18px"]}
              textTransform="uppercase"
              color="blackAlpha.900"
              textAlign="center"
            >
              Caso Clínico 4
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "18px", "24px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              Disfunção de Prótese
            </Text>
          </Flex>
          <Flex justify="space-around">
            <Image
              src={imgCard29_4}
              h={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
        </Flex>
      </Flex>

      <LargeCard
        title="Propedêutica 4"
        image={imgCard3}
        list={[
          {
            hora: "11H20",
            avatar: avatarDrManuelFelipe,
            text: "Tipos de Próteses Valvares ",
          },
          {
            hora: "11H30",
            avatar: avatarDrTalitaMialksi2,
            text: "Disfunção de Próteses Valvares",
          },
          {
            hora: "11H40",
            avatar: avatarDrBrunaErbano2,
            text: "“Eco Point-Of-Care” na avaliação da Próteses Valvares ",
          },
          {
            hora: "11H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <LargeCard
        title="Diagnóstico 4"
        image={imgCard4}
        list={[
          {
            hora: "11H20",
            avatar: avatarDrMinaRomano,
            text: "Avaliação Ecocardiográfica na suspeita de Disfunção de Próteses Valvares ",
          },
          {
            hora: "11H30",
            avatar: avatarRodrigoBarretto,
            text: "Ecocardiografia Tridimensional e Transesofágica de Disfunção de Próteses Valvares ",
          },
          {
            hora: "11H40",
            avatar: avatarDrArianePacheco,
            text: "Multimodalidade de imagem na Avaliação da disfunção de Próteses Valvares ",
          },
          {
            hora: "11H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />
      <LargeCard
        title="INTERVENÇÃO 4"
        image={imgCard11}
        list={[
          {
            hora: "11H20",
            avatar: avatarDrIdelzuita,
            text: "Tratamento da trombose de Prótese Valvar",
          },
          {
            hora: "11H30",
            avatar: avatarDrRenatoLopes2,
            text: "Evidências Científicas Atuais para Anticoagulação em Próteses Valvares",
          },
          {
            hora: "11H40",
            avatar: avatarPedroNicz,
            text: "Novidades no Tratamento Percutâneo das Disfunções de Próteses Valvares",
          },
          {
            hora: "11H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
          visibility="hidden"
        >
          09H30
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
          pos="relative"
          minH={["80px", "100px", "120px"]}
        >
          <Flex flexDir="column" flex="1" p="20px" justify="center">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["20px", "20px", "20px", "26px"]}
              textTransform="uppercase"
              textAlign={["left", "center"]}
            >
              LUNCH TIME
            </Text>
          </Flex>
          <Image
            src={imgCard29_5}
            h={["80px", "100px", "120px"]}
            pos="absolute"
            right="0"
          />
        </Flex>
      </Flex>

      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          13H30
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={"column"}
          pos="relative"
        >
          <Flex flexDir="column" flex="1">
            <Flex justify="space-between" pos="absolute" right="0" top="0">
              <Image
                src={imgCard6}
                h={["0px", "0px", "0px", "0px", "100px"]}
                objectFit="contain"
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" mt="20px" zIndex="1">
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "28px", "32px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              Deep Dives 3
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "14px", "20px", "24px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              O Ensino da Cardiologia na Era Digital
            </Text>
          </Flex>

          <Flex justify="space-around" mt="30px" flexWrap="wrap">
            <Image
              src={avatarDrEduardoLapa}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarDrRemoHolanda}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarDrTiagoBignoto}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarDrRhanderson}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          14H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={"column"}
          pos="relative"
        >
          <Flex flexDir="column" flex="1">
            <Flex
              justify="space-between"
              pos="absolute"
              right="0"
              left="0"
              top="0"
            >
              <Flex flexDir="column" m={["10px", "20px"]}>
                <Text
                  color={borderHour}
                  fontWeight="600"
                  fontSize={["14px", "14px", "18px", "18px"]}
                >
                  MAIN ARENA
                </Text>

                <Text
                  color="black"
                  fontWeight="600"
                  fontSize={["14px", "14px", "18px", "18px"]}
                >
                  HUB 3
                </Text>
              </Flex>

              <Image
                src={imgCard29_6}
                h={["0px", "0px", "0px", "0px", "130px"]}
                objectFit="contain"
              />
            </Flex>
          </Flex>
          <Flex flexDir="column" mt={["40px", "20px"]} zIndex="1">
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "28px", "32px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              main arena - canada case
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "14px", "20px", "24px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              Discussão – Valve-in-Valve Case
            </Text>
          </Flex>

          <SimpleGrid
            columns={[2, 5]}
            justify="space-around"
            mt="30px"
            px={["0px", "7%"]}
            spacing="20px"
          >
            <Image
              src={avatarDrAttilioGalhardo2}
              boxSize="150px"
              objectFit="contain"
            />
            <Image src={avatarPedroNicz2} boxSize="150px" objectFit="contain" />
            <Image
              src={avatarDrTiagoBignoto}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarDrBrunaErbano}
              boxSize="150px"
              objectFit="contain"
            />
            <Image
              src={avatarDrLeonardoGuimaraes2}
              boxSize="150px"
              objectFit="contain"
            />
          </SimpleGrid>
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          14H50
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["row"]}
          minH={["80px", "100px", "120px"]}
          px="20px"
        >
          <Image
            src={imgCard9}
            h={["80px", "100px", "120px"]}
            display={["none", "none", "none", "flex"]}
            visibility={["none", "none", "none", "hidden"]}
          />
          <Flex flexDir="column" flex="1" p="20px" justify="center">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["20px", "20px", "20px", "26px"]}
              textTransform="uppercase"
              textAlign={["left", "center"]}
            >
              Apresentação do Trabalho Ganhador
            </Text>
          </Flex>
          <Image src={imgCard9} h={["80px", "100px", "120px"]} />
        </Flex>
      </Flex>

      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          15h00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
        >
          <Flex justify="center" align="center" p={["10px", "20px"]}>
            <Image
              src={avatarDrTiagoBignoto}
              boxSize={["80px", "100px", "120px"]}
              objectFit="contain"
            />
          </Flex>
          <Flex flexDir="column" flex="1" align="center" p={["10px", "20px"]}>
            <Text
              fontWeight="600"
              fontSize={["14px", "14px", "14px", "18px"]}
              textTransform="uppercase"
              color="blackAlpha.900"
              textAlign="center"
            >
              Caso Clínico 5
            </Text>
            <Text
              mt="10px"
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "18px", "24px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              Insuficiência Tricúspide
            </Text>
          </Flex>
          <Flex justify="center">
            <Image
              src={imgCard29_7}
              h={["60px", "80px", "100px"]}
              objectFit="contain"
            />
          </Flex>
        </Flex>
      </Flex>

      <LargeCard
        image={imgCard3}
        title="Propedêutica 5"
        list={[
          {
            hora: "15H20",
            avatar: avatarDrBarbaraValente,
            text: "História Natural da Insuficiência Tricúspide",
          },
          {
            hora: "15H30",
            avatar: avatarDrTiagoBignoto5,
            text: "Exame Físico da Insuficiência Tricúspide",
          },
          {
            hora: "15H40",
            avatar: avatarDrThiagoRosa,
            text: "“Eco Point-Of-Care” na Insuficiência Tricúspide ",
          },
          {
            hora: "15H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />
      <LargeCard
        image={imgCard4}
        title="Diagnóstico 5"
        list={[
          {
            hora: "15H20",
            avatar: avatarDrMuriloJardim,
            text: "Parâmetros Ecocardiográficos da Insuficiência Tricúspide ",
          },
          {
            hora: "15H30",
            avatar: avatarDrMarceloViera,
            text: "Métodos Avançados da ecocardiografia na avaliação da Insuficiência Tricúspide",
          },
          {
            hora: "15H40",
            avatar: avatarDrBernadoLopes,
            text: "Multimodalidade no Diagnóstico da Insuficiência Tricúspide",
          },
          {
            hora: "15H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />
      <LargeCard
        image={imgCard5}
        title="Intervenção 5"
        list={[
          {
            hora: "15H20",
            avatar: avatarDrBrunaErbano2,
            text: "Indicação de Intervenção na Insuficiência Tricúspide",
          },
          {
            hora: "15H30",
            avatar: avatarDrFelipeCampos,
            text: "Tratamento Percutâneo da Insuficiência Tricúspide ",
          },
          {
            hora: "15H40",
            avatar: avatarDrFranciscoCosta,
            text: "Abordagem Cirúrgica da Insuficiência Tricúspide Vale tudo para preservar a valva nativa?",
          },
          {
            hora: "15H50",
            avatar: avatarPainel,
            text: "Painel",
          },
        ]}
      />

      <Flex justify="space-between" flexDir={["column", "column", "row"]}>
        <Text
          borderLeftColor={borderHour}
          borderLeftWidth={["0px", "2px"]}
          paddingLeft="5px"
          color="white"
          fontWeight="600"
          fontSize="20px"
          alignSelf="center"
        >
          16H00
        </Text>
        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          p={["10px", "20px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={"column"}
          pos="relative"
        >
          <Flex flexDir="column" zIndex="1">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "28px", "32px"]}
              textTransform="uppercase"
              textAlign={"center"}
            >
              Heart Team 4.0
            </Text>
          </Flex>

          <Flex justify="space-around" mt="10px" flexWrap="wrap">
            <Image
              src={avatarDrTiagoBignoto}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarDrFranciscoCosta2}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarRodrigoBarretto2}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
            <Image
              src={avatarDrPedroLemos}
              boxSize="150px"
              objectFit="contain"
              m="10px"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        justify="space-between"
        flexDir={["column", "column", "row"]}
        flex="1"
      >
        <Flex flexDir="column" justify="center">
          <Text
            borderLeftColor={borderHour}
            borderLeftWidth={["0px", "2px"]}
            paddingLeft="5px"
            color="white"
            fontWeight="600"
            fontSize="20px"
            alignSelf="center"
          >
            16H40
          </Text>

          <Text
            borderLeftColor={borderHour}
            borderLeftWidth={["0px", "2px"]}
            paddingLeft="5px"
            color="white"
            fontWeight="600"
            fontSize="20px"
            alignSelf="center"
          >
            17H00
          </Text>
        </Flex>

        <Flex
          bg={bgItens}
          flex="1"
          borderRadius={["20px", "40px"]}
          ml={["0px", "0px", "30px"]}
          flexDir={["column", "column", "row"]}
          pos="relative"
          minH={["100px", "120px", "140px"]}
        >
          <Flex flexDir="column" flex="1" p="20px" justify="center">
            <Text
              color="black"
              fontWeight="600"
              fontSize={["18px", "18px", "18px", "24px"]}
              textTransform="uppercase"
              textAlign={["left", "center"]}
            >
              VALVE CONNECTION
            </Text>
          </Flex>
          <Flex pos="absolute" right="15" align="center" top="0" bottom="0">
            <Image src={imgCard12} h={["60px", "80px", "100px"]} />
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  );
}

const LargeCard = ({ list, image, title }) => (
  <Flex flexDir="column" pos="relative">
    {list.map((item, index) => (
      <Flex justify="space-between" flex="1" key={title + index}>
        <Flex flexDir="column" justify="center">
          {index === 0 && (
            <Text
              display="gone"
              my="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "16px", "18px", "26px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              .
            </Text>
          )}
          <Text
            borderLeftColor={borderHour}
            borderLeftWidth="2px"
            paddingLeft="5px"
            color="white"
            fontWeight="600"
            fontSize={["12px", "20px"]}
            alignSelf="center"
          >
            {item?.hora}
          </Text>
        </Flex>
        <Flex
          bg={bgItens}
          flex="1"
          py={"10px"}
          px={["10px", "20px"]}
          ml={["5px", "30px"]}
          borderTopLeftRadius={index === 0 && ["20px", "40px"]}
          borderTopRightRadius={index === 0 && ["20px", "40px"]}
          borderBottomLeftRadius={index + 1 === list.length && ["20px", "40px"]}
          borderBottomRightRadius={
            index + 1 === list.length && ["20px", "40px"]
          }
          flexDir="column"
        >
          {index === 0 && (
            <Text
              my="10px"
              color="black"
              fontWeight="600"
              fontSize={["14px", "16px", "18px", "26px"]}
              textTransform="uppercase"
              textAlign="center"
            >
              {title}
            </Text>
          )}
          <Flex align="center">
            <Image
              src={item?.avatar}
              boxSize={["70px", "100px", "120px"]}
              objectFit="contain"
            />
            <Text
              ml={["10px", "20px"]}
              mt="10px"
              color="blackAlpha.800"
              fontWeight="500"
              fontSize={["12px", "14px", "16px", "22px"]}
              textTransform="uppercase"
              mr={["0px", "0px", "10%"]}
            >
              {item?.text}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    ))}
    <Flex
      pos="absolute"
      right="0"
      align="center"
      top="0"
      bottom="0"
      display={["none", "none", "flex"]}
    >
      <Image src={image} h="300px" objectFit="contain" />
    </Flex>
  </Flex>
);

export default Day29;
