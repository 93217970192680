import api from "../services/api";

export const uploadFiles = (url, files, onUploadProgress = () => {}) =>
  new Promise((resolve, reject) => {
    if (files.filter((item) => item.path).length === 0) reject();
    const data = new FormData();
    files.forEach((photo) => {
      if (photo.path) {
        data.append("files", photo);
      }
    });
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (e) => {
        const progress = (e.loaded * 100) / e.total;
        e.progress = progress.toFixed(0);
        return onUploadProgress(e);
      },
    };
    api
      .post(url, data, config)
      .then((resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
