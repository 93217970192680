import produce from 'immer';

const INITIALSTATE = {
  user: null,
  token: null,
};

export default function user(state = INITIALSTATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@user/SET_USER': {
        draft.user = action.payload.user;
        break;
      }
      case '@user/SET_TOKEN': {
        draft.token = action.payload.token;
        break;
      }
      case '@user/LOGOUT': {
        draft.user = null;
        draft.token = null;
        break;
      }
      default:
        return state;
    }
  });
}
